import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"
import { Link, graphql } from "gatsby"
import { Container, Row, Col } from "reactstrap"

const TraniningAssistants = data => {
  const { allSanityPerson } = data.data
  const assistants = allSanityPerson.group.filter(
    person =>
      person.fieldValue !== "Training Assistants" &&
      person.fieldValue !== "Faculty"
  )
  //   console.log("assistants", assistants)

  return (
    <Layout>
      <SEO title="Training Assistants" description="Training Assistants" />
      <div class="page-headline">
        <div class="container">
          <div class="section-heading text-center">
            <h1 className="text-uppercase"> Training Assistants</h1>
          </div>
        </div>
      </div>
      <div className="page-content mt-5">
        <Container>
          {assistants.map(assist => {
            const categoryLink = assist.edges[0].node.category.find(
              cat => cat.title === assist.fieldValue
            )

            return (
              <div className="assistants mb-5" key={assist.fieldValue}>
                <h5>
                  <Link
                    className="text-orange"
                    to={
                      categoryLink && categoryLink.linkTo
                        ? `/trainings/${categoryLink.linkTo}`
                        : "/trainings"
                    }
                  >
                    {assist.fieldValue}
                  </Link>
                </h5>
                <Row>
                  {assist.edges.map(person => (
                    <Col
                      key={person.node.id}
                      md={4}
                      lg={3}
                      sm={6}
                      className="mb-5"
                    >
                      <div className="profile-wrap h-100">
                        <div
                          className="profile-image"
                          style={{
                            backgroundImage: `url(${person.node.image.asset.fixed.src})`,
                          }}
                        ></div>
                        <div className="text-center p-4">
                          <p className="text-orange font-weight-bold">
                            {person.node.name}
                          </p>
                          <p className="font-weight-bold text-blue">
                            {person.node.position}
                          </p>
                          {person.node.shortDescription &&
                          person.node.shortDescription.length > 160 ? (
                            <p>
                              {person.node.shortDescription.substring(0, 160)}
                              ...
                            </p>
                          ) : (
                            <p>
                              {person.node.shortDescription
                                ? person.node.shortDescription
                                : null}
                            </p>
                          )}
                          {person.node.externalProfileLink ? (
                            <a
                              className="text-orange font-weight-bold"
                              href={person.node.externalProfileLink}
                            >
                              Read More
                            </a>
                          ) : null}
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            )
          })}
        </Container>
      </div>
    </Layout>
  )
}

export default TraniningAssistants

export const trainQuery = graphql`
  query trainQuery {
    allSanityPerson {
      group(field: category___title) {
        edges {
          node {
            id
            name
            shortDescription
            position
            category {
              id
              title
              linkTo
            }
            externalProfileLink
            image {
              asset {
                fixed(width: 350) {
                  src
                }
              }
            }
          }
        }
        fieldValue
      }
    }
  }
`
